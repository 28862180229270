/* eslint-disable import/no-unresolved */

/* eslint-disable react/button-has-type */
import React from 'react';

import Image from 'next/image';
import { useRouter } from 'next/router';

import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { isAndroidModal } from 'src/redux/slices/courseConsumption';

import RippleButton from '../../HomePage/HeroSection/RippleButton';
import styles from './androidModal.module.scss';

function AndroidModal() {
  const router = useRouter();
  const isAndroidDataMweb = useSelector((state) => state.courseConsumption.isAndroidModalPopUp);
  const hideCloseIcon = (isAndroidDataMweb?.screenName === 'videoDashboard' ||
    isAndroidDataMweb?.screenName === 'courseDashboard' || isAndroidDataMweb?.screenName === 'ncert');
  const dispatch = useDispatch();

  const goToApp = () => {
    const isAndroid = /(android)/i.test(navigator.userAgent);

    const appLink = isAndroid ? 'https://studyiq.page.link/Zk8q' : 'https://apps.apple.com/in/app/adda247/id6451410946';

    router.push(appLink);
  };

  const handleCancel = () => {
    if (!isAndroidDataMweb.disableClose) {
      dispatch(isAndroidModal({ show: false }));
    }
  };

  const closeIcon = <svg display="none" />;

  return (
    isAndroidDataMweb?.show && (
      <Modal
        open={isAndroidDataMweb?.show}
        onClose={handleCancel}
        closeOnOverlayClick={false} // Prevent closing on overlay click
        closeOnEsc={false} // Prevent closing on ESC key press
        closeIcon={
          hideCloseIcon && closeIcon
        }
        center
      >
        <Image src="/android-img1.png" width={412} height={412} alt="Android Image" />
        <Image src="/android-img2.png" width={412} height={95} alt="Android Image 2" />
        <div className={styles.modalText}>Latest Quiz & Articles | Current Affairs & Latest Exams Information</div>
        <div className={styles.buttonDiv}>
          <RippleButton
            onClick={goToApp}
            className={styles.modalButton}
            rippleColor="#fff"
            opacity={0.38}
            width="35px"
            height="35px"
          >
            Open in App
          </RippleButton>
        </div>
      </Modal>
    )
  );
}

export default AndroidModal;
